.dashboard-container {
  .success-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Dim background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .popup-content {
      border-radius: 16px;
      border-top: 1px solid var(--Glass-Stroke, rgba(255, 255, 255, 0.08));
      background: #363a3d;

      /* Glass/Modal */
      box-shadow: 16px 24px 64px -24px rgba(255, 255, 255, 0.04) inset,
        0px 24px 64px -16px rgba(0, 0, 0, 0.24),
        0px 8px 12px 0px rgba(255, 255, 255, 0.04) inset;
      color: var(--Noble-Black-0, #fff);
      padding: 20px 30px;
      border-radius: 12px;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-weight: bold;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
      animation: fadeIn 0.3s ease-in-out;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 50px;
        width: 50px;
      }

      p {
        margin: 0;
      }
    }

    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  padding: 20px;
  .input-url-container {
    .upload-text-container {
      h2 {
        color: var(--Noble-Black-0, #fff);
        font-family: Poppins;
        font-size: 28px;
        margin-bottom: 10px;
      }

      p {
        color: var(--Noble-Black-300, #9b9c9e);

        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.15px;
        width: 90%;
      }
    }

    .url-container {
      display: flex;
      align-items: center;
      margin-top: 20px;
      outline: none;

      .input-url {
        display: flex;
        width: 90%;
        height: 70px;
        padding: 16px 100px 16px 24px;
        align-items: center;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 12px;
        background: var(--Noble-Black-700, #131619);
        color: white;
        outline: #363a3d;
        font-size: 20px;
        margin-right: 15px;
      }

      button {
        display: flex;
        height: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        line-height: 24px; /* 150% */
        letter-spacing: 0.15px;
        text-decoration: none;
        margin-left: -100px;
        background: #b6f09c;
        outline: none;
        width: 80px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .upload-audio-container {
    margin-top: 50px;
    h1 {
      color: var(--Noble-Black-0, #fff);
      font-family: Poppins;
      font-size: 28px;
      margin-bottom: 10px;
    }

    p {
      color: var(--Noble-Black-300, #9b9c9e);

      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
      margin-bottom: 20px;
    }

    .upload-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      height: 200px;
      flex-shrink: 0;
      border-radius: 16px;
      border: 2px dashed #938e8e;
      background: var(--Noble-Black-800, #0d0f10);
      padding: 3%;

      /* Shadow/Extra Large */
      box-shadow: 0px 25px 50px -12px rgba(6, 7, 8, 0.16),
        0px 8px 10px -6px rgba(6, 7, 8, 0.06);

      label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 50px;
          height: 50px;
          flex-shrink: 0;
          margin-bottom: 10px;
          cursor: pointer;
        }

        h3 {
          color: var(--Noble-Black-0, #fff);
          font-family: Poppins;
          font-size: 20px;
          margin-bottom: 5px;
          text-align: center;
        }

        p {
          color: var(--Noble-Black-300, #9b9c9e);

          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          line-height: 24px; /* 150% */
          letter-spacing: 0.15px;
          text-align: center;
        }
      }

      .file-upload-input {
        display: flex;
        color: white;
      }
    }
  }

  .submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: 30px;
    margin-top: 60px;

    .skip-btn {
      display: flex;
      width: 178px;
      height: 49px;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid #686b6e;
      background: #000;
      color: #a8a8a8;
      cursor: pointer;
    }

    .submit {
      display: flex;
      width: 178px;
      height: 49px;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 12px;
      background: var(--Stem-Green-500, #b6f09c);
      cursor: pointer;
    }

    a {
      width: 178px;
      text-decoration: none;
      .view-list {
        display: flex;
        height: 49px;

        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 12px;
        background: var(--Stem-Green-500, rgb(245, 153, 153));
        cursor: pointer;
      }
    }
  }
}
